import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { WEBSITE_NAME } from "@/config";
export default {
  mixins: [validationMixin],
  name: "LoginUser",
  data: function data() {
    return {
      WEBSITE_NAME: WEBSITE_NAME,
      showPassword: false,
      loading: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required: required,
        email: email
      },
      password: {
        required: required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    togglePassword: function togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateState: function validateState(name) {
      var _this$$v$form$name = this.$v.form[name],
          $dirty = _this$$v$form$name.$dirty,
          $error = _this$$v$form$name.$error;
      return $dirty ? !$error : null;
    },
    resetForm: function resetForm() {
      var _this = this;

      this.form = {
        email: null,
        password: null
      };
      this.$nextTick(function () {
        _this.$v.$reset();
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      var email = this.$v.form.email.$model;
      var password = this.$v.form.password.$model;
      this.loading = true; // send login request

      this.$store.dispatch(LOGIN, {
        username: email,
        password: password
      }) // go to which page after successfully login
      .then(function () {
        _this2.loading = true;

        _this2.$router.push({
          name: "dashboard"
        });
      }).catch(function () {
        _this2.loading = false;
      });
    }
  },
  computed: _objectSpread({}, mapState({
    errors: function errors(state) {
      return state.auth.errors;
    }
  }))
};